import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function AssociationTable({ triplets }) {
    console.log('Triplets data:', triplets);

    if (!triplets || !Array.isArray(triplets)) {
      return <div>No data available</div>;
    }
  
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Entity One</TableCell>
              <TableCell>Entity One Type</TableCell>
              <TableCell>Entity Two</TableCell>
              <TableCell>Entity Two Type</TableCell>
              <TableCell>Relationship Type</TableCell>
              <TableCell>PubMed ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {triplets.map((association, index) => (
              <TableRow key={index}>
                <TableCell>{association.entity_one_name}</TableCell>
                <TableCell>{association.entity_one_type}</TableCell>
                <TableCell>{association.entity_two_name}</TableCell>
                <TableCell>{association.entity_two_type}</TableCell>
                <TableCell>{association.relation_name}</TableCell>
                <TableCell>{association.pmid}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

export default AssociationTable;