import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import KgVis from '../../components/KgVis';
import AssociationTable from '../../components/AssociationTable';
import { Container, Row, Col} from "react-bootstrap";
import { beApiUrl } from '../../api/be_config';
import _ from 'lodash';

export default function Graphshow() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryData = {
    "entity_one_name": queryParams.get('nodeonename'),
    "entity_one_type": queryParams.get('nodeonetype'),
    "entity_two_name": queryParams.get('nodetwoname'),
    "entity_two_type": queryParams.get('nodetwotype'),
    "relation": queryParams.get("relationtype"),
    "db_identifier": queryParams.get("db_identifier"),
    "size_limit": 100
  }
  //const [graphInfo, setGraphInfo] = useState();
  const [edges, setEdges] = useState([])
  const [nodes, setNodes] = useState([])
  const [triplets, setTriplets] = useState([])
  const [rdyRender, setRdyRender] = useState(false)
  
  useEffect(()=>{
    fetch( beApiUrl + "kgpage/kgquery/v1/",{
      method:"POST",
      headers: {
          "Content-Type": "application/json",
        },
      body: JSON.stringify(queryData)
  }
    )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to get the relations.");
      }
      return response.json();
    })
    .then((data)=>{
      console.log('API Response:', data);
      if (!_.isEmpty(data)){
        setNodes(data.nodes)
        setEdges(data.edges)
        setTriplets(data.triplets)
        console.log('Triplets:', data.triplets); // Log triplets here
      }
    })
    .catch((error) => {
      console.log(error)
  })
  },[])

  useEffect(()=>{
    if (nodes != [] && edges != []){
        setRdyRender(true)
    }
  },[nodes, edges, triplets])
return(
  <Container>
  <Row>
    <Col xs={2}></Col>
    <Col xs={8}>
      {
        rdyRender? <KgVis nodes={nodes} edges={edges}/>  : null
      }
    </Col>
    <Col xs={2}></Col>
  </Row>
  <Row>
    <Col>
      {
        rdyRender? <AssociationTable triplets={triplets}/>  : null
      }
    </Col> 
  </Row>
</Container>
)
}