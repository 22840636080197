import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Navbar, Nav, NavDropdown, } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate } from "react-router-dom";
import { beApiUrl } from "../../api/be_config";
import { NavLink } from 'react-router-dom';
import './Inputquery.css';

export default function Inputquery() {
  const [nodeOneName, setNodeOneName] = useState("");
  const [nodeOneType, setNodeOneType] = useState([]);
  const [nodeTwoName, setNodeTwoName] = useState("");
  const [nodeTwoType, setNodeTwoType] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [relation, setRelation] = useState([]);
  const [relationType, setRelationType] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(beApiUrl + "kgpage/entity_type/v1/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ size_limit: 10 }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to get the entity types.");
        }
        return response.json();
      })
      .then((data) => {
        setTypeOptions(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetch(beApiUrl + "kgpage/relation_type/v1/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ size_limit: 10 }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to get the relation types.");
        }
        return response.json();
      })
      .then((data) => {
        setRelationType(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function kgSubmit(e) {
    e.preventDefault();
    const uri =
      "/showgraph?" +
      "nodeonename=" +
      nodeOneName +
      "&nodeonetype=" +
      JSON.stringify(nodeOneType) +
      "&nodetwoname=" +
      nodeTwoName +
      "&nodetwotype=" +
      JSON.stringify(nodeTwoType) +
      "&relationtype=" +
      JSON.stringify(relation);
    navigate(encodeURI(uri));
  }

  return (
    <>
      <Container className="intro-container">
        <Row style={{ margin: "5px" }}>
          <Col>
            <img src="/fig6.jpg" alt="Related Visual Content" className="img-fluid" />
          </Col>
        </Row>
      </Container>

      <Container className="no-padding">
        <Row style={{ margin: "20px 0px 0px 0px" }}>
          <Col xs={12} md={6}>
            <form id='kginput' onSubmit={kgSubmit}>
              <Form.Group className='mb-3'>
                <Form.Label>Entity I Name</Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e) => setNodeOneName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Entity I Type</Form.Label>
                <Multiselect
                  options={typeOptions}
                  displayValue='name'
                  onRemove={(e1, e2) => setNodeOneType(e1)}
                  onSelect={(e1, e2) => setNodeOneType(e1)}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Entity II Name (optional)</Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e) => setNodeTwoName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Entity II Type (optional)</Form.Label>
                <Multiselect
                  options={typeOptions}
                  displayValue='name'
                  onRemove={(e1, e2) => setNodeTwoType(e1)}
                  onSelect={(e1, e2) => setNodeTwoType(e1)}
                />
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>Relation Type (optional)</Form.Label>
                <Multiselect
                  options={relationType}
                  displayValue='name'
                  onRemove={(e1, e2) => setRelation(e1)}
                  onSelect={(e1, e2) => setRelation(e1)}
                />
              </Form.Group>
              <Button variant='custom' type='submit' className='custom-btn'>
                Search
              </Button>
            </form>
          </Col>
          <Col xs={12} md={6}>
            <img src="/fig10.jpg" alt="Related Visual Content" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </>
  );
}