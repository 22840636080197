import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Inputquery from "./pages/kgPage/Inputquery";
import InputqueryADKG from "./pages/kgPage/Inputquery_adkg";
import InputqueryMDKG from "./pages/kgPage/Inputquery_mdkg";
import Graphshow from "./pages/kgPage/Graphshow";
import NewPage from "./pages/kgPage/NewPage";

function App() {
  return (
    <BrowserRouter>
      <Header>
        <Routes>
          <Route path='/inputquery' element={<Inputquery />} /> 
          <Route path='/inputquery_ADKG' element={<InputqueryADKG />} /> 
          <Route path='/inputquery_MDKG' element={<InputqueryMDKG />} /> 
          <Route path='/showgraph' element={<Graphshow />} />
          <Route path='/newpage' element={<NewPage />} />
          <Route path='/' element={<Inputquery />} />
        </Routes>
      </Header>
    </BrowserRouter>
  );
}

export default App;