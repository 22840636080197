import React from "react";
import './NewPage.css';  // 确保引入 CSS 文件

export default function NewPage() {
  return (
    <div className="new-page-container new-page">
      
      {/* 第一个知识图谱 */}
      <div className="kg-section">
        <h2>ADKG‘s Construction Pipeline</h2>
        <section className="content-section">
          <img src="ADKG.jpg" alt="Data Collection"/>
          <div className="text-block">
            <p>General Pipeline: corpus generation, model building, entity linking, ADKG construction.</p>
          </div>
        </section>
      </div>

      {/* 第二个知识图谱 */}
      <div className="kg-section">
        <h2>MDKG's Construction Pipeline</h2>
        <section className="content-section">
          <img src="MDKG.jpg" alt="Data Collection"/>
          <div className="text-block">
            <p>a. The detailed information we extracted from the literature. </p>
            <p>b. Construction Workflow of MDKG. The construction of MDKG is rooted in the extraction of
literature-based information and the integration of existing biomedical knowledge graph. A.
When working with scientific literature, we adhere to the following three steps. Step 1: Thoroughly define the entity types, relationship types, and contextual information for MDKG.
Step 2: Use existing tools like BERN2, GPT-4, and GPT-3.5, combined with manual annotations, to process training data following an active learning approach. Step 3: Employ NLP
techniques to extract triples from literature abstracts, derive contextual features from the
main body of the article, and gather side information from literature websites, culminating in structured knowledge. B. Separately, we tap into existing biomedical databases. From
there, we select and integrate 12 high-quality knowledge bases to align seamlessly with the
literature-based KG.</p>
          </div>
        </section>
      </div>
    </div>
  );
}
