import { GraphCanvas } from "reagraph";

 function KgVis(props) {
  return (
    <div  className="kgvis-box">
      <GraphCanvas 
        edgeLabelPosition="natural" 
        labelType="all"
        layoutType="radialOut2d"
        draggable
        nodes={props.nodes}
        edges={props.edges} 
        />
      </div>
  );
}
export default KgVis;