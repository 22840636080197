import { useState } from "react";
import { Container, Navbar, Nav, Col, Row } from "react-bootstrap";
import './Header.css';

const navigation = [
  { id: 0, name: "Home", href: "/inputquery" },
  { id: 2, name: "Knowledge Graphs", href: "/newpage" },
  { id: 3, name: "ADKG", href: "/inputquery_ADKG" },
  { id: 4, name: "MDKG", href: "/inputquery_MDKG" },
  { id: 1, name: "About Us", href: "https://www.med.unc.edu/bigs2/" },
];

export default function Header(props) {
  const [isHovered, setIsHovered] = useState(
    navigation.map((item) => ({ id: item.id, value: false }))
  );

  function handleMouseEnter(event) {
    const toggleHover = isHovered.map((hover) => {
      if (parseInt(event.target.id, 10) === hover.id) {
        return { id: hover.id, value: true };
      }
      return hover;
    });
    setIsHovered(toggleHover);
  }

  function handleMouseLeave(event) {
    const toggleHover = isHovered.map((hover) => {
      if (parseInt(event.target.id, 10) === hover.id) {
        return { id: hover.id, value: false };
      }
      return hover;
    });
    setIsHovered(toggleHover);
  }

  return (
    <>
      <Navbar style={{ height: "90px", padding: "0px" }} expand="lg" className="bg-white">
        <Container style={{ height: "100%", padding: "0px" }}>
          <Row
            style={{
              height: "100%",
              padding: "0px",
              width: "100%",
            }}
            className="align-items-center justify-content-between"
          >
            <Col xs={6} style={{ height: "100%", padding: "0px" }}>
              <Navbar.Brand
                href="https://www.med.unc.edu/bigs2/"
                style={{ height: "100%", padding: "0px", display: "flex", alignItems: "center" }}
              >
                <img
                  src="/logo1.jpg" // Replace with your logo path
                  alt="Logo"
                  className="logo-image"
                />
              </Navbar.Brand>
            </Col>
            <Col
              xs={6}
              style={{
                height: "100%",
                padding: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Nav>
                {navigation.map((item) => (
                  <Nav.Link
                    id={item.id.toString()}
                    key={item.id}
                    href={item.href}
                    className="nav-link-custom"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {item.name}
                  </Nav.Link>
                ))}
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <div className="bg-gray-300">
        <div className="min-h-screen p-2 max-w-7xl mx-auto">
          {props.children}
        </div>
      </div>
    </>
  );
}